import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo animate-loop" alt="logo" />
        <code className="animate-loop">
          s00n
        </code>
      </header>
    </div>
  );
}

export default App;
